<template>
    <div class="outerBox">
		<div class="redCircleBox">
			<img src="@/assets/page2/redCircle.png" class="redCircle" alt="">
			<active-number class="itemValue" :number="itemValue"></active-number>
		</div>
		<div class="itemName">{{itemName}}</div>
	</div>
</template>

<style lang="less" scoped>
.outerBox {
	height: 100%;

	.redCircleBox {
		width: 100%;
		height: 71.91%;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;

		.redCircle {
			position: absolute;
			max-height: 100%;
			max-width: 100%;
			aspect-ratio: 1/1;
		}

		.itemValue {
			height: 2.78vh;
			font-size: 1.85vh;
			line-height: 2.78vh;
			font-family: 'titleFont', sans-serif;
			text-shadow: 0px 0px 9px rgba(255,59,59,0.8);
			background: linear-gradient(180deg, #fff 42%, #ffd03b 100%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
	}

	.itemName {
		width: 100%;
		height: 1.944vh;
		text-align: center;
		font-size: 1.3vh;
		line-height: 1.944vh;
		color: #fff;
		font-family: 'pingfangSc', sans-serif;
		margin-top: 4.49%;
	}

}
</style>

<script>
const activeNumber = () => import('@/views/components/activeNumber.vue');

export default {
	props:['itemName','itemValue'],
	components:{
		activeNumber
	},
	data(){
		return {
		}
	},
	mounted(){
		return false;
	},
	methods: {
	}
}
</script>

